import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ImageValid } from "../validations/ImageValid";
import { toast } from "react-toastify";
import { useSetInstaUrlMutation } from "../../redux/instauserApi";
import { UrlValid } from "../validations/UrlValid";

function AddInstaUrlModal(props) {
  const [setInstaUrl] = useSetInstaUrlMutation();
  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState("");
  const [image, setImage] = useState("");
  const [disable, setDisable] = useState(false);
  const [imageErr, setImageErr] = useState(false);
  const handleClose = () => {
    setTitle("");
    setImage("");
    setTitleErr("");
    setImageErr("");
    props.setShow(false);
  };
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "title") {
      setTitle(value);
      const err = UrlValid(name, value);
      setTitleErr(err);
    }
  };
  const handlechangeimage = (e) => {
    let { name } = e.target;

    const image = e.target.files[0];
    if (name === "instaimage") {
      setImage(image);
      let checkImage = ImageValid(name, image);
      setImageErr(checkImage);
    }
  };
  const onsubmit = async (e) => {
    setDisable(true);

    setTimeout(() => {
      setDisable(false);
    }, 4000);
    const checkTitle = UrlValid("insta_url", title);
    if (checkTitle) {
      setTitleErr(checkTitle);
      return false;
    }
    let checkImage = ImageValid("image", image);
    if (checkImage) {
      setImageErr(checkImage);
      return false;
    }

    const formdata = new FormData();
    formdata.append("insta_url", title);
    formdata.append("insta_image", image);
    setInstaUrl(formdata).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
        setDisable(false);
      }
    });
  };
  return (
    <div>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>
            Add Instagram Url
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Url </Form.Label>
              <Form.Control
                name="title"
                onChange={handlechange}
                type="text"
              ></Form.Control>
              <span style={{ color: "red" }}>{titleErr}</span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Image</Form.Label>
              <img
                style={{ width: "100px" }}
                src={image}
                className="img-fluid"
                alt=""
              />
              <Form.Control
                type="file"
                name="instaimage"
                onChange={handlechangeimage}
                accept="image/png, image/jpg, image/jpeg"
              />
              <span style={{ color: "red" }}>{imageErr}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit} disabled={disable}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddInstaUrlModal;
