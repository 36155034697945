import { addInstaUrlApi, getInstaUrlApi, getInstaUserApi, updateInstaUrlapi } from "../components/constant/Api";
import { myApi } from "./api";

export const instauserApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getInstaUser: builder.query({
      query: () => ({
        url: getInstaUserApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getInstaUrl: builder.query({
      query: () => ({
        url: getInstaUrlApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["instaurl"],
    }),
    setInstaUrl: builder.mutation({
      query: (post) => ({
        url: addInstaUrlApi,
        method: "POST",
        body:post
      }),
      invalidatesTags: (_) => ["instaurl"],
    }),
    updateInstaUrl: builder.mutation({
      query: (post) => ({
        url: updateInstaUrlapi+"/"+post.id,
        method: "PUT",
      }),
     
      invalidatesTags: (_) => ["instaurl"],
    }),
  }),
});

export const { useGetInstaUserQuery,useGetInstaUrlQuery,useSetInstaUrlMutation,useUpdateInstaUrlMutation } = instauserApi;
