import React,{useState} from "react";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import SideBar from "../../widgets/SideBar";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useGetInstaUrlQuery, useUpdateInstaUrlMutation } from "../../../redux/instauserApi";
import AddInstaUrlModal from "../../partial/AddInstaUrlModal";
import { toast } from "react-toastify";

function InstaUrl() {
  const { data } = useGetInstaUrlQuery();
  const [updateInstaUrl] = useUpdateInstaUrlMutation();
  const [show, setShow] = useState(false)

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "insta_url",
      text: "Instagram Url",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
        key: "insta_image",
        text: "Image",
        className: "email",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <><img src={record.insta_image} alt=""/></>;
        },
      },
    {
      key: "createdAt",
      text: "Start Date",
      className: "email",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.createdAt;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },
    {
      key: "status",
      text: "Status",
      className: "Action ",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button
              style={{
                border: "1px solid #fff",
                borderRadius: "3px",
                background: record.is_active === "1" ? "green" : "#d10202",
              }}
              onClick={() => {
                updateAlert(record.id);
              }}
            >
              <i className="bi bi-lock" style={{ color: "#fff" }}></i>
            </button>
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const handleShow=()=>{
    setShow(true)
  }

  const updateAlert = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => urlUpdateStatus(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const urlUpdateStatus = async (id) => {
    let data = {
      id,
    };
    updateInstaUrl(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
      }
    });
  };

  return (
    <>
      <Header />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex align-items-center mb-4">
          <h1>Instagram Url List</h1>
          <div>
            <button className="btn btn-primary" onClick={handleShow}>Add +</button>
          </div>
        </div>
        <section className="section dashboard">
          <div className="card">
            <div className="card-body pt-4">
              <ReactDatatable
                config={config}
                records={data}
                columns={columns}
              />
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <AddInstaUrlModal setShow={setShow} show={show}/>
    </>
  );
}

export default InstaUrl;
