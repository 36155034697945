import { baseUrl } from "./BaseUrl";

export const loginApi=baseUrl+"/admin-login" 
export const changePasswordApi=baseUrl+"/change-password"

//=============== Dashboard ================
export const getDashboardapi="/get-dashboard"

//=================== User ==================
export const getUserApi="/get-user"
export const addAminBonusApi="/add-admin-bonus"

//==================== Transaction ===============
export const getUserTransactionApi="/get-user-transaction"
export const getAdminTransactionApi="/get-admin-transaction"
export const getUserSendMinAmountApi="/get-user-send-min-amount"

//==================== Wallet =====================
export const approveMinamountApi="/approve-min-amount"

//================= Top Rank Date ==================
export const addTopRankDateApi="/add-top-rank-date"
export const getTopRankDateApi="/get-top-rank-date"
export const getAllTopRankDateApi="/get-all-top-rank-date"
export const updatetopRankDateApi="/update-top-rank-date"

//================ Top Rank user =================
export const getTopRankUserApi="/get-top-rank-user"
export const getPrevTopRankUserApi="/get-prev-top-rank-user"

//================= youtubeLink ==================
export const addYoutubeUrlApi="/add-youtube-url"
export const getyoutubeUrlapi="/get-youtube-url"

//================= LivetokenDate ==================
export const addLiveTokenDateApi="/add-livetoken-date"
export const getLiveTokenaDateApi="/get-livetoken-date"
export const updateLiveTokenDateStatusapi="/update-livetoken-date-status"

//============== Stage ========================
export const getCurrentStageApi="/get-current-stage"

//================ Insta user ==================
export const getInstaUserApi="/get-insta-user"
export const addInstaUrlApi="/add-insta-url"
export const getInstaUrlApi="/get-insta-url"
export const updateInstaUrlapi="/update-insta-url"