import { urlRegexpatter } from "../pattern/Pattern";

export const UrlValid = (name, value) => {
    let error = "";
  
    if (value === "") {
      error = "This field is required";
      return error;
    }
    if (!urlRegexpatter.test(value)) {
      error = `Please enter valid url`;
      return error;
    }
    return error;
  };
  