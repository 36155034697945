import React from "react";
import { Link } from "react-router-dom";

function SideBar() {
  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/dashboard"
                ? "nav-link active"
                : "nav-link"
            }
            to="/dashboard"
          >
            <i className="bi bi-grid"></i>
            <span>Dashboard</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/users"
                ? "nav-link active"
                : "nav-link"
            }
            to="/users"
          >
            <i className="bi bi-megaphone"></i>
            <span>Users</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/insta-users"
                ? "nav-link active"
                : "nav-link"
            }
            to="/insta-users"
          >
            <i className="bi bi-megaphone"></i>
            <span>Instagram Users</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/insta-url"
                ? "nav-link active"
                : "nav-link"
            }
            to="/insta-url"
          >
            <i className="bi bi-megaphone"></i>
            <span>Instagram Urls</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/user-transaction"
                ? "nav-link active"
                : "nav-link"
            }
            to="/user-transaction"
          >
            <i className="bi bi-megaphone"></i>
            <span>User Transaction</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/admin-transaction"
                ? "nav-link active"
                : "nav-link"
            }
            to="/admin-transaction"
          >
            <i className="bi bi-megaphone"></i>
            <span>Admin Transaction</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/user-send-min-amount"
                ? "nav-link active"
                : "nav-link"
            }
            to="/user-send-min-amount"
          >
            <i className="bi bi-megaphone"></i>
            <span>User Send Min Amount</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/top-rank-user"
                ? "nav-link active"
                : "nav-link"
            }
            to="/top-rank-user"
          >
            <i className="bi bi-megaphone"></i>
            <span>Top Rank User</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/prev-top-rank-user"
                ? "nav-link active"
                : "nav-link"
            }
            to="/prev-top-rank-user"
          >
            <i className="bi bi-megaphone"></i>
            <span>Prev Top Rank User</span>
          </Link>
        </li>
        {/* <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/youtube-url"
                ? "nav-link active"
                : "nav-link"
            }
            to="/youtube-url"
          >
            <i className="bi bi-megaphone"></i>
            <span>Youtube Url</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              window.location.pathname === "/live-token-date"
                ? "nav-link active"
                : "nav-link"
            }
            to="/live-token-date"
          >
            <i className="bi bi-megaphone"></i>
            <span>Live token Date</span>
          </Link>
        </li> */}
      </ul>
    </aside>
  );
}

export default SideBar;
