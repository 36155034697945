import React,{useState,useEffect} from "react";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import SideBar from "../../widgets/SideBar";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import { useGetUserTransactionQuery } from "../../../redux/transactionApi";
import { transTypeEnum } from "../../constant/enum";

function UserTransaction() {
  const { data:record } = useGetUserTransactionQuery();
  const [transType, setTransType] = useState("Buy")
  const [data, setData] = useState([])

  useEffect(() => {
    if(record && transType){
       const filterData=record?.filter((list)=>{return (list.trans_type==transType)})
       setData(filterData)
    }
 }, [record,transType])


  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "from_address",
      text: "From Address",
      className: "name",
      align: "left",
      sortable: true,
    },
    // {
    //   key: "to_address",
    //   text: "To Address",
    //   className: "email",
    //   align: "left",
    //   sortable: true,
    // },
    {
      key: "chain",
      text: "Chain",
      className: "mobile_number",
      align: "left",
      sortable: true,
    },
    {
      key: "currency",
      text: "Currency",
      className: "mobile_number",
      align: "left",
      sortable: true,
    },
    {
      key: "amount",
      text: "Amount",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "usdt_amount",
      text: "USDT Amount",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "ukc_token",
      text: "Ukc Token",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
        key: "transaction_id",
        text: "Transaction Id",
        className: "email",
        align: "left",
        sortable: true,
      },
    {
      key: "start_date",
      text: "Date",
      className: "email",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.createdAt;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },
  ];

  const otherColumns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "wallet_address",
      text: "Wallet Address",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "ukc_token",
      text: "Ukc Token",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
        key: "transaction_id",
        text: "Transaction Id",
        className: "email",
        align: "left",
        sortable: true,
      },
    {
      key: "start_date",
      text: "Date",
      className: "email",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.createdAt;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const handleTransType=(value)=>{
    setTransType(value)
  }

  return (
    <>
      <Header />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex align-items-center mb-4">
          <h1>Users Transaction List</h1>
          <div className="filter-button">
           {transTypeEnum?.map((list)=>{
            return (<button key={list.key} className={list.value==transType?"active":""} onClick={()=>{handleTransType(list.key)}}>{list.value}</button>)
           })}
        </div>
        </div>

        <section className="section dashboard">
          <div className="card">
            <div className="card-body pt-4">
              <ReactDatatable
                config={config}
                records={data}
                columns={transType=="Buy"? columns:otherColumns}
              />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default UserTransaction;
