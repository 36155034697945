import React,{useState} from "react";
import {useGetUserQuery } from "../../../redux/userApi";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header";
import SideBar from "../../widgets/SideBar";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import AddAdminBonus from "../../partial/AddAdminBonus";

function Users() {
  const { data } = useGetUserQuery();
  const [show, setShow] = useState(false)
  const [id, setId] = useState("")

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "country_code",
      text: "Country Code",
      className: "mobile_number",
      align: "left",
      sortable: true,
    },
    {
      key: "mobile_number",
      text: "Mobile Number",
      className: "mobile_number",
      align: "left",
      sortable: true,
    },
    {
      key: "wallet_address",
      text: "Wallet Address",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "start_date",
      text: "Start Date",
      className: "email",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.createdAt;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },
    {
      key: "action",
      text: "Add bonus",
      className: "Action d-flex",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
           
            <button
              style={{
                border: "1px solid #fff",
                borderRadius: "3px",
                background:"green",
                padding: "0px 7px",
              }}
              onClick={() => {
                handleShow(record.id);
              }}
             
            >
              Add Bonus
            </button>
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const handleShow=(value)=>{
    setId(value)
    setShow(true)
  }

  return (
    <>
      <Header />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex align-items-center mb-4">
          <h1>Users List</h1>
        </div>

        <section className="section dashboard">
          <div className="card">
            <div className="card-body pt-4">
            <ReactDatatable
                config={config}
                records={data}
                columns={columns}
              />
              
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <AddAdminBonus setShow={setShow} show={show} id={id}/>
    </>
  );
}

export default Users;
